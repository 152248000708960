





























import { activeCallStore, uiStore } from '@/store'
import { ActiveCall } from '@/store/modules/activeCall/models'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Wrapper from '../Wrapper.vue'
import CallDetails from './CallDetails/index.vue'

const PADDING_X = 8 * 4 // q-px-sm

@Component({
  name: 'TheCallMd',
  components: {
    AddMembers: () => import('@/components/Calls/ActiveCall/AddMembers/index.vue'),
    CallDetails,
    DraggableResizable: () => import('@/components/UI/DraggableResizable.vue'),
    Wrapper,
  },
})
export default class TheCallMd extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  // default window position and size
  w = 328
  h = 600

  x = 0
  y = 0
  z = 9900

  minWidth = 328
  minHeight = 376

  currentWidth = 0

  metrikaResizeTimeout: number | null = null
  isDragging: boolean | null = null

  get showAddMembers () {
    return activeCallStore.state.uiMdDisplayType === 'ADD_MEMBERS'
  }

  private created () {
    const size = uiStore.state.callWindowSize
    if (size) {
      this.w = size.width
      this.h = size.height
    }

    this.updateCurrentWidth(this.w)
  }

  private onResize (left: number, top: number, width: number) {
    this.updateCurrentWidth(width)
  }

  private updateCurrentWidth (width: number) {
    this.currentWidth = width - PADDING_X
  }

  /**
   * Triggered when call window resize finishes.
   * Reports to Metrika if last resize happened at least 10 secs ago.
   * Save window size
   */
  private onResizeStop (left: number, top: number, width: number, height: number) {
    if (this.metrikaResizeTimeout !== null) {
      clearTimeout(this.metrikaResizeTimeout)
    }

    const size = `${width}x${height}`
    const reporter = () => window.goal('callMdSize', { size })

    this.metrikaResizeTimeout = setTimeout(reporter, 10000)

    uiStore.mutations.setCallWindowSize({ width, height })
  }

  private onDragging () {
    this.isDragging = true
  }

  private onDragStop () {
    this.isDragging = false
  }
}
