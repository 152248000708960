











































import { activeCallStore } from '@/store'
import { ActiveCall, CallEntity, CallMembersDisplayType } from '@/store/modules/activeCall/models'
import { CallOnliner } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { callsLogger } from '@/loggers'

@Component({
  components: {
    CallMemberCard: () => import('./CallMemberCard/index.vue'),
    CallMemberItem: () => import('./CallMemberItem.vue'),
    DirectCallMemberCard: () => import('./DirectCallMemberCard.vue'),
    ScrollableList: () => import('@/components/Calls/ScrollableList.vue'),
    ScrollArea: () => import('@/components/Calls/ScrollArea.vue'),
  },
})
export default class TheCallMdContent extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  get members (): CallOnliner[] {
    return this.call.connectedMembers ?? []
  }

  get showAsCards (): boolean {
    return this.membersDisplayType === 'CARDS'
  }

  get showAsList (): boolean {
    return this.membersDisplayType === 'LIST'
  }

  /**
   * For direct calls always display other side, even if it's not connected.
   */
  get directCallEntity (): CallEntity | undefined {
    if (!this.isCallDirect) return

    // call.jid == other side's jid
    const j = this.call.jid
    const e = this.members.find(m => m.jid === j) ?? j
    return activeCallStore.getters.createCallEntity(e)
  }

  /**
   * Always display at least one user (self) in group/task call,
   * even if call hasn't connected yet.
   */
  get groupOrTaskCallEntities (): CallEntity[] {
    return activeCallStore.getters.connectedEntities
  }

  get isCallDirect (): boolean {
    return activeCallStore.getters.isActiveCallDirect
  }

  get membersDisplayType (): CallMembersDisplayType {
    return activeCallStore.state.membersDisplayType
  }

  private pinMember (jid: string): void {
    window.goal('callControls', { callControls: 'Даблклик — Развернуть' })
    callsLogger.log('Selecting presenter', jid)
    activeCallStore.actions.setUiDisplayType('fullscreen')
    activeCallStore.actions.setUiLgLayoutType('PRESENTER')
    activeCallStore.actions.pinMember(jid)
  }
}
