



























import CallControlsStrip from '@/components/Calls/ActiveCall/CallControlsStrip/index.vue'
import { activeCallStore } from '@/store'
import { ActiveCall } from '@/store/modules/activeCall/models'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CallDetailsContent from './CallDetailsContent/index.vue'
import CallDetailsHeader from './CallDetailsHeader/index.vue'

@Component({
  components: {
    CallDetailsContent,
    CallDetailsHeader,
    CallControlsStrip,
    DeniedError: () => import('./DeniedError.vue'),
  },
})
export default class CallDetails extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  @Prop({
    type: Number,
    default: 0,
  }) readonly width!: number

  @Prop({
    type: Boolean,
    default: false,
  }) readonly isDragging!: boolean

  get camDenied () {
    return activeCallStore.state.camDenied
  }

  get micDenied () {
    return activeCallStore.state.micDenied
  }
}
